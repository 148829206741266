import * as React from "react";
import { AskAboutQuery_SuggestedIntent } from "../../../../../graphql-types";
import Section from "../../../ui-library/section/section";
import QuestionLink from "../../../ui-library/question-link/question-link";
import { EventCategory } from "../../../../utils/analytics";

interface SuggestedIntentProps {
  suggestedIntent: AskAboutQuery_SuggestedIntent;
}

const SuggestedIntent: React.FC<SuggestedIntentProps> = ({
  suggestedIntent,
}) => {
  return (
    <Section heading="You might also want to ask..." align="right">
      <QuestionLink
        to={`/blockchain/ask/${suggestedIntent.alias}/`}
        eventCategory={EventCategory.Section_SuggestedAsk}
      >
        {suggestedIntent.displayName}
      </QuestionLink>
    </Section>
  );
};

export default SuggestedIntent;
