import * as React from "react";
import PageHeading from "../../../ui-library/page-heading/page-heading";
import { AskAboutContext } from "../../../../site-context/ask-about-context";
import { addQuestionMarkToTerm } from "../../../../utils/format-term";
import Container from "../../../ui-library/container/container";
import RecentlyAskedQuestions from "../../recently-asked-questions/recently-asked-questions";

const NoDetectedIntent: React.FC = () => {
  const {
    store: {
      askInput: [{ term }],
    },
  } = React.useContext(AskAboutContext);

  const heading =
    !!term && term.trim() !== ""
      ? "Sorry, we couldn’t find an answer for you"
      : "What question do you have about blockchain?";

  const subHeading =
    !!term && term.trim() !== ""
      ? `You asked: ${addQuestionMarkToTerm(term)}`
      : "Ask away and we’ll show you an answer";

  return (
    <div>
      <PageHeading subHeading={subHeading}>{heading}</PageHeading>

      <Container fullWidthOnMobile={true}>
        <RecentlyAskedQuestions />

        {/* TODO */}
        {/* <FeaturedVideo /> */}
      </Container>
    </div>
  );
};

export default NoDetectedIntent;
