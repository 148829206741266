import * as React from "react";
import { PageProps } from "gatsby";
import { BasicLayout } from "../../../components/layout";
import SEO from "../../../components/seo/seo";
import LoadingState from "../../../components/ui-library/loading-state/loading-state";
import { useGetAuthToken } from "../../../hooks/use-get-auth-token";
import SignInNudge from "../../../components/internal/sign-in-nudge/sign-in-nudge";
import BlockchainIntentPreview from "../../../components/internal/intent-preview/blockchain-intent-preview";

type AllIntentsPageProps = PageProps<null, null>;

const AllIntents: React.FC<AllIntentsPageProps> = ({ location }) => {
  const { loading, userToken } = useGetAuthToken();

  // @ts-ignore no activeIntent
  const activeIntent: ActiveIntent | undefined = location?.state?.activeIntent;

  if (loading) {
    return (
      <BasicLayout>
        <LoadingState />
      </BasicLayout>
    );
  }

  if (!userToken) {
    return (
      <BasicLayout>
        <SignInNudge />
      </BasicLayout>
    );
  }

  return (
    <BasicLayout>
      <SEO
        title="Blockchain all intents"
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <BlockchainIntentPreview activeIntent={activeIntent} />
    </BasicLayout>
  );
};

export default AllIntents;
